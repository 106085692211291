import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import VideoContainer from '../video-contanier/video-container';
import ImageContainer from '../image-container';
import SectionContainerLayout from '../section-container-layout/section-container-layout';
import Clamp from 'react-multiline-clamp';
import CustomLink from '../custom-link/index';
import Fade from 'react-reveal/Fade';

export const TheParagraph = (props) => {
  return (
    <>
      <Typography color='textPrimary' variant='h6' gutterBottom>
        {props.paragraphTitle}
      </Typography>
      {props.clamp ? (
        <Clamp lines={props.lines}>
          <Typography color='textSecondary' variant='body1' className='clamp'>
            {props.text}
          </Typography>
        </Clamp>
      ) : (
        <Typography color='textSecondary' variant='body1'>
          {props.text}
        </Typography>
      )}

      {props.list && props.list.length !== 0 && (
        <Box clone p={2}>
          <ul>
            {props.list.map((item, idx) => {
              return (
                <li key={idx}>
                  <Box py={1}>
                    <Typography variant='body1' color='textPrimary'>
                      {item}
                    </Typography>
                  </Box>
                </li>
              );
            })}
          </ul>
        </Box>
      )}

      {props.ctaLink && (
        <Box my={2}>
          <Fade bottom>
            <CustomLink type={8} linkUrl={props.ctaLink} linkText={props.ctaText} external={props.ctaLinkExternal} />
          </Fade>
        </Box>
      )}
    </>
  );
};

export default function SectionDescription(props) {
  return (
    <SectionContainerLayout title={props.title}>
      {props.left ? (
        <Grid container spacing={3} justify='space-between'>
          <Box order={{ xs: 2, md: 1 }} clone>
            <Grid item md={6} xs={12}>
              <Fade bottom>
                <TheParagraph
                  ctaLink={props.ctaLink}
                  ctaText={props.ctaText}
                  ctaLinkExternal={props.ctaLinkExternal}
                  text={props.paragraphText}
                  paragraphTitle={props.paragraphTitle}
                  clamp={props.clamp}
                  lines={props.lines}
                  list={props.dataList}
                />
              </Fade>
            </Grid>
          </Box>
          <Box order={{ xs: 1, md: 2 }} clone>
            <Grid item md={6} xs={12}>
              <Fade bottom>
                {!props.image ? (
                  <VideoContainer
                    src={props.url}
                    thumbNail={props.videoThumbNail}
                    controls={props.videoControls}
                    callbackFn={props.callbackFn}
                    muted={props.muted}
                  />
                ) : (
                  <ImageContainer filename={props.filename} altText={props.paragraphTitle || '#GMIS2021'} />
                )}
              </Fade>
            </Grid>
          </Box>
        </Grid>
      ) : (
        <Grid container spacing={3} justify='space-between'>
          <Fade bottom>
            <Box order={{ xs: 1, md: 1 }} clone>
              <Grid item md={6} xs={12}>
                <Box mt={{ md: 0, sm: 4, xm: 4, lg: 0 }}>
                  {!props.image ? (
                    <VideoContainer src={props.url} thumbNail={props.videoThumbNail} />
                  ) : (
                    <ImageContainer filename={props.filename} altText={props.paragraphTitle || '#GMIS2021'} />
                  )}
                </Box>
              </Grid>
            </Box>
          </Fade>
          <Fade bottom>
            <Box order={{ xs: 2, md: 2 }} clone>
              <Grid item md={6} xs={12}>
                <TheParagraph
                  ctaText={props.ctaText}
                  text={props.paragraphText}
                  paragraphTitle={props.paragraphTitle}
                />
              </Grid>
            </Box>
          </Fade>
        </Grid>
      )}
      {props.children}
    </SectionContainerLayout>
  );
}
