import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, makeStyles, createStyles } from '@material-ui/core';
import colors from '../../constants/colors';
import ReactPlayer from 'react-player';
import ImageContainer from '../image-container';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';

const useStyles = makeStyles((theme) =>
  createStyles({
    spacingColor: {
      position: 'relative',
      zIndex: 1,
      backgroundColor:
        theme.palette.type === 'dark'
          ? `${colors.primary.dark}${colors.transparency.button}`
          : `${colors.primary.light}${colors.transparency.button}`,
    },
    cornerCardBorder: {
      border: '3px solid',
      borderRadius: '4px',
      borderColor: `${theme.palette.primary.main} !important`,
      '&::before': {
        backgroundColor: theme.palette.background.default,
      },
      '&::after': {
        backgroundColor: theme.palette.background.default,
      },
    },
    playIcon: {
      zIndex: 2,
      fontSize: '50px',
      color: theme.palette.background.default,
    },
  })
);
// let REFARRAY = null;
const VideoContainer = (props) => {
  const playerBoxRef = useRef(null);
  const playerRef = useRef(null);
  const classes = useStyles();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHideThumbNail, setIsHideThumbNail] = useState(false);
  const [playerHeight, setPlayerHeight] = useState(400);
  const [fullScreenState, setFullScreenState] = useState(false);
  const hideThumbNail = () => {
    definePlayerHeight();
    setIsHideThumbNail(true);
    setIsPlaying(true);

    if (props.callbackFn) props.callbackFn(false);
  };

  useEffect(() => {
    definePlayerHeight();

    // if (playerRef.current != null) {

    // }
  });

  useEffect(() => {
    if (props.muted === true) {
      // setIsHideThumbNail(false);
      // setIsPlaying(false);
    } else {
      setIsHideThumbNail(false);
      setIsPlaying(false);
    }
    return () => {};
  }, [props.muted]);

  const showThumbNail = () => {
    const fullScreenMode = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
    if (fullScreenMode) {
      exitFullscreen();
    } else {
      setIsHideThumbNail(false);
      setIsPlaying(false);
    }

    if (props.callbackFn) props.callbackFn(true);
  };
  // const muteOtherVideo = () => {
  //   if (props.callbackFn) props.callbackFn(true);
  // };
  // const playHoverHandler = () => {
  //   REFARRAY.seekTo(0, 'seconds');
  //   setIsPlaying(true);
  // };
  // const pauseHoverHandler = () => {
  //   setIsPlaying(false);
  //   setIsHideThumbNail(false);
  // };

  useEffect(() => {
    if (!fullScreenState) {
      // console.log('state:full:screen:', fullScreenState, isPlaying, isHideThumbNail);
      setIsPlaying(false);
      setIsHideThumbNail(false);
    }
  }, [fullScreenState]);

  const exitFullscreen = () => {
    const fullScreenMode = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
    if (fullScreenMode) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
      // console.log('state:full:screen:exit:', fullScreenState, isPlaying, isHideThumbNail);
      definePlayerHeight();
      setFullScreenState(!fullScreenMode);
    }
  };

  const definePlayerHeight = () => {
    if (playerBoxRef.current != null) {
      if (playerBoxRef.current.clientHeight !== 0) setPlayerHeight(playerBoxRef.current.clientHeight);
    }
  };

  return (
    <Grid container>
      <Grid item className={`video cornerBorder ${classes.cornerCardBorder}`} xs={12}>
        <Box p={1} className={classes.spacingColor}>
          <Box
            display={props.thumbNail ? (isPlaying ? 'block' : 'none') : 'block'}
            onMouseLeave={showThumbNail}
            onClick={(e) => {
              e.target.blur();
              showThumbNail();
            }}>
            <ReactPlayer
              config={{
                file: {
                  attributes: {
                    disablePictureInPicture: true,
                  },
                },
              }}
              playing={isPlaying}
              volume={1}
              playsinline={true}
              className='reactPlayer'
              muted={false}
              url={props.src}
              height={props.thumbNail ? playerHeight : '100%'}
              width='100%'
              controls={props.controls !== undefined ? props.controls : true}
              pip={false}
              ref={playerRef}
              onMouseLeave={exitFullscreen}
              onEnded={exitFullscreen}
              // ref={(accordionContent) => (REFARRAY = accordionContent)}
              // onPlay={muteOtherVideo}
              // onMouseEnter={() => playHoverHandler()}
              // onMouseLeave={() => pauseHoverHandler()}
            />
          </Box>
          {props.thumbNail && (
            <Box
              ref={playerBoxRef}
              display={!isPlaying && !isHideThumbNail ? 'block' : 'none'}
              onClick={hideThumbNail}
              onMouseEnter={hideThumbNail}>
              <PlayCircleFilledWhiteIcon className={`absolute ${classes.playIcon}`} />
              <ImageContainer filename={props.thumbNail} altText='#GMIS2021' />
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default VideoContainer;
