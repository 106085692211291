import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import Layout from '../../components/layout';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import SectionDescription, { TheParagraph } from '../../components/section-description/section-description';
import ImageContainer from '../../components/image-container';
import SEO from '../../components/seo';

const AboutUsPage = () => {
  return (
    <Layout
      children={
        <>
          <SEO lang='en' title='About Us | Global Manufacturing & Industrialisation Summit' />
          <SectionContainerLayoutWithFilter
            title='MESSAGE FROM GMIS'
            children={
              <TheParagraph
                text={
                  <>
                    <Typography gutterBottom>
                      In overcoming any challenge, acting in union and forging networks will always lead to greater
                      achievements than operating alone in isolation. In the sphere of the global economy, this is as
                      true for the world’s manufacturers as it is for any other sector – and it is why the Global
                      Manufacturing and Industrialisation Summit (GMIS) was born. A joint initiative led by the UAE and
                      the United Nations Industrial Development Organization, and held under the patronage of His
                      Highness Sheikh Mohamed bin Zayed Al Nahyan, Crown Prince of Abu Dhabi and Deputy Supreme
                      Commander of the UAE Armed Forces, GMIS is the converging point for all stakeholders who drive the
                      world of manufacturing towards future prosperity through discussions, debates and knowledge
                      exchange – envisioning what is to come and how to attain it.
                    </Typography>
                    <Typography gutterBottom>
                      A globally unique cross-industry platform for key players – from manufacturers themselves, to
                      governments and NGOs, to CEOs and industry experts, to innovators and investors – to redefine a
                      sector that has underpinned economies for generations, GMIS combines individual ideas and insights
                      into a collective roadmap during a time when the pace of technology-driven transformation and the
                      advent of the Fourth Industrial Revolution have shaken the world’s economic kaleidoscope. The
                      ramifications of these factors, and the dramatic change they will catalyze, bring opportunities,
                      but also uncertainty for manufacturers, and create the need for transformational solutions that
                      can only be identified through unified effort and endeavor.
                    </Typography>
                    <Typography gutterBottom>
                      GMIS aims to ensure that, for manufacturing, the opportunity outstrips the uncertainty; that the
                      Fourth Industrial Revolution’s impact on the sector is a regenerative one; and that
                      manufacturing’s response to times of challenge is not only to define how it can prosper as an
                      industry, but how it can align itself with the objectives of the UN Sustainable Development Goals,
                      in order to bring benefits that help to build stronger societies and communities, as well as
                      stronger businesses. It seeks to illustrate the need to invest in our world’s greatest natural
                      resource – people – and enable them to develop their skills to innovate and to reshape the world.
                      It looks to envisage what the future workforce will look like and how can it be sustained. And it
                      is committed to ensuring that economic growth, and the policies behind it, has manufacturing at
                      its core, in recognition of the sector’s status as a powerhouse of industry, a key
                      revenue-generator, a creator of jobs, and an essential spoke in the wheel of global prosperity.
                    </Typography>
                    <Typography gutterBottom>Badr Al Olama</Typography>
                    <Typography gutterBottom> Head of GMIS Organising Committee</Typography>
                  </>
                }
                ctaText='Download the #GMIS2017 Outcomes Booklet'
              />
            }
          />
          <SectionContainerLayoutWithFilter title='ABOUT GMIS'>
            <Grid container spacing={3} justify='space-between'>
              <Box order={{ xs: 2, md: 1 }} clone>
                <Grid item xs={12} md={6}>
                  <Typography paragraph color='textSecondary'>
                    Manufacturers play a crucial role in the exponentially evolving world economy. They generate wealth
                    for investors, pay taxes to governments and employ half a billion people worldwide. They are also
                    facing challenges from rapid transformations in technology and global value chains that are driven
                    by the fluctuating impact of the digital revolution, automation and geopolitical reordering. These
                    challenges can no longer be resolved in isolation, they require a global solution – a solution that
                    will also connect manufacturing closer to the UN Sustainable Development Goals (UN SDGs), for the
                    benefits of businesses as well as the communities they serve.
                  </Typography>
                  <Typography paragraph color='textSecondary'>
                    The Global Manufacturing and Industrialisation Summit (GMIS) was established in 2015 as an industry
                    association to build bridges between manufacturers, governments & NGOs, technologists, and investors
                    in harnessing the Fourth Industrial Revolution’s transformation of manufacturing to the regeneration
                    of the global economy. A joint initiative by the United Arab Emirates and the United Nations
                    Industrial Development Organization (UNIDO), GMIS is a platform that presents the manufacturing
                    sector with an opportunity to contribute towards global good, working to the benefit of all.
                  </Typography>
                  <Typography paragraph color='textSecondary'>
                    GMIS is the world’s first cross-industrial and cross-functional platform that offers a voice and
                    venue for leaders to transform manufacturing, encourage greater investment in capabilities, foster
                    innovation and drive global skills development. Uniting influential delegates, including visionary
                    world leaders, expert industry CEOs and specialist researchers and academics, the summit aims to
                    place manufacturing at the heart of economic regeneration and government policymaking, utilising it
                    as a tool for global cooperation and collaboration.
                  </Typography>
                </Grid>
              </Box>
              <Box order={{ xs: 1, md: 2 }} clone>
                <Grid item md={6} xs={12}>
                  <Box mb={{ xs: 3 }}>
                    <ImageContainer filename='about_us_uae_un' altText='about_us_uae_un' />
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </SectionContainerLayoutWithFilter>
          {/* <SectionDescription
            title='Previous GMIS Editions'
            left={true}
            image={true}
            filename='2020'
            ctaText='WATCH THE #GMIS2020 VIRTUAL SUMMIT ON DEMAND'
            paragraphTitle='#GMIS2020 Goes Digital'
            paragraphText={
              <>
                <Typography paragraph>
                  Under the theme – Glocalisation: Towards Sustainable and Inclusive Global Value Chains, the virtual
                  edition of #GMIS2020 bought together high-profile thought-leaders and business pioneers from around
                  the world, for the very first time, to shape the future of manufacturing, to discuss the impact of a
                  crisis on global value chains, and highlight the role of 4IR technologies in restoring in restoring
                  economic and social activities.
                </Typography>

                <Typography paragraph>Topics discussed by experts at the GMIS Virtual Summit included:</Typography>

                <Typography paragraph>
                  A trillion-dollar question: in an age of digital restoration, how are leaders repurposing our
                  economies to deal with a post-crisis era? 4IR for a more resilient manufacturing sector?
                </Typography>

                <Typography paragraph>Glocalisation: from global to local?</Typography>
                <Typography paragraph>The Policymakers’ Challenge: navigating through a recession</Typography>
                <Typography paragraph>Restoring prosperity in a post-pandemic world</Typography>
                <Typography paragraph>
                  Panel Discussion: rise of the machines: robots in a post-pandemic world
                </Typography>
                <Typography paragraph>
                  Pushing the limits in the healthcare, telecoms and education sectors: bent, but not broken?
                </Typography>
                <Typography paragraph>
                  Standardisation: adapting digital standardisation rules to match a post-crisis world Energies of the
                  future: the time for clean energy is now
                </Typography>
              </>
            }
          />
          <SectionDescription
            left={true}
            image={true}
            filename='2019'
            paragraphTitle='#GMIS2019'
            paragraphText={
              <>
                <Typography paragraph>
                  In July 2019, the manufacturing community gathered together in the city of Yekaterinburg – the
                  industrial heartland of Russia – for the second edition of the Global Manufacturing and
                  Industrialisation Summit, #GMIS2019.
                </Typography>
                <Typography paragraph>
                  First announced by President Vladimir Putin in May 2018, #GMIS2019 was held under the theme of
                  ‘nature-inspired technologies’ – also known as biomimetic design and biomimicry – the science of
                  mapping design challenges to the natural world to find innovative solutions. The 2019 summit explored
                  the impact of innovation and Fourth Industrial Revolution technologies on the global manufacturing
                  sector through the worldwide dissemination of knowledge, best practices and standards, while
                  highlighting the role of manufacturing in enabling global prosperity through advancing the Sustainable
                  Development Goals (SDGs) of the United Nations.
                </Typography>
              </>
            }
          />
          <SectionDescription
            left={true}
            image={true}
            filename='2017'
            paragraphTitle='#GMIS2017'
            paragraphText={
              <>
                <Typography paragraph>
                  The first edition of the Global Manufacturing and Industrialisation Summit was held under the
                  patronage of His Highness Sheikh Mohamed bin Zayed Al Nahyan, Crown Prince of Abu Dhabi and Deputy
                  Supreme Commander of the UAE Armed Forces, at the Paris Sorbonne University Abu Dhabi in March 2017.
                  #GMIS2017 left a lasting impression on all participants through global partnerships and innovations,
                  bringing together over 3,000 leaders from governments, businesses and civil society to advance
                  manufacturing and industrial development globally and to identify trends and opportunities in
                  technology and innovation; global value chains; skills, employment and education; sustainability and
                  the environment; infrastructure; standards and stakeholder alignment.
                </Typography>
                <Typography paragraph>
                  #GMIS2017 was the world’s first cross-industrial and cross-functional platform that offered a voice
                  and venue for leaders to transform manufacturing, encourage greater investment in capabilities, foster
                  innovation and drive global skills development. Uniting influential delegates, including visionary
                  world leaders, expert industry CEOs and specialist researchers and academics, the summit placed
                  manufacturing at the heart of economic regeneration and government policymaking, utilising it as a
                  tool for global cooperation and collaboration.
                </Typography>
                <Typography paragraph>
                  GMIS will continue to work with the United Nations Industrial Development Organization to align
                  manufacturing activities more closely with progress towards the United Nations Sustainable Development
                  Goals. It will also work with GMIS partners to expand relationships between four constituents –
                  manufacturers, government and NGOs, technologists, and investors – both within and between
                  geographies, through participation in relevant GMIS conferences roadshows and events.
                </Typography>
              </>
            }
          /> */}
        </>
      }
    />
  );
};

export default AboutUsPage;
